<template>
  <div class="member-layout">
    <slot />
  </div>
</template>

<script setup>
useSiteHead()

</script>

<style lang="stylus" scoped>
.member-layout
  width 100%
  height 100vh
  /**
    修正 Safari toolbar 蓋過高度問題
    https://juejin.cn/post/6997744474126303268
    https://app.asana.com/0/0/1204791186534408/f
   */
  // // Safari Only Hack
  @supports (font: -apple-system-body) and (-webkit-appearance: none)
    height -webkit-fill-available
</style>